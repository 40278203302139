<template>
  <div
    v-if="computedDataParams"
    style="
      max-width: 100%;
      width: 100%;
      height: 100%;
      margin-right: auto;
      margin-left: auto;
      height: 100%;
      background-color: #ffffff;
    "
  >
    <div
      style="padding: 28px 32px; flex-direction: row; display: flex; justify-content: space-between; flex-wrap: wrap"
    >
      <div>
        <div style="display: flex; margin-bottom: 24px; align-items: center">
          <img
            :src="appLogo"
            height="50px"
            width="50px"
            alt="logo"
            style="margin: 12px; background-position: center center"
          />
          <span
            style="color: #333333; font-family: 'Orbitron'; font-weight: 700; font-size: 1.25rem; line-height: 1.75rem"
          >
            FANATY
          </span>
        </div>
        <span style="display: block; color: #333333 !important; font-family: 'Inter', sans-serif; margin-bottom: 8px ;">
          <span style="color: #333333; display: flex; align-items: center; justify-content: start; letter-spacing: 0.7px;">
            <svg
              style="width: 16px; height: 16px; margin-right: 5px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#ff3232"
                d="M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
              />
            </svg>
            www.fanaty.com
          </span>
        </span>

        <span style="display: block; color: #333333 !important; font-family: 'Inter', sans-serif; margin-bottom: 8px ;">
          <span style="color: #333333; display: flex; align-items: center; justify-content: start; letter-spacing: 0.7px;">
            <svg
              style="width: 16px; height: 16px; margin-right: 5px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#ff3232"
                d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
              />
            </svg>
            support@fanaty.com
          </span>
        </span>

        <span style="display: block; color: #333333 !important; font-family: 'Inter', sans-serif; margin-bottom: 8px ;">
          <span style="color: #333333; display: flex; align-items: center; justify-content: start; letter-spacing: 0.7px;">
            <svg
              style="width: 16px; height: 16px; margin-right: 5px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#ff3232"
                d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
              />
            </svg>
            (888) 965-3262
          </span>
        </span>
      </div>

      <div>
        <p
          style="
            padding: 0;
            margin: 0;
            display: flex;
            color: #333333;
            margin-bottom: 16px;
            align-items: center;
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.75rem;
            font-family: 'Inter', sans-serif;
          "
        >
          {{ t('Invoice') }} #{{ computedDataParams.id }}
          <span
            :style="{
              backgroundColor: `${resolveColor(computedDataParams.status)}30`,
              color: resolveColor(computedDataParams.status),
              caretColor: resolveColor(computedDataParams.status),
              fontSize: '12px',
              height: '20px',
              padding: '0 8px',
              borderRadius: '12px',
              textTransform: 'capitalize',
              fontWeight: '600',
              marginLeft: '8px',
              alignItems: 'center',
              cursor: 'default',
              lineHeight: '20px',
              maxWidth: '100%',
              outline: 'none',
              overflow: 'hidden',
              padding: '0 12px',
              position: 'relative',
              textDecoration: 'none',
              transitionDuration: '0.28s',
              transitionProperty: 'box-shadow, opacity',
              transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
              verticalAlign: 'middle',
              whiteSpace: 'nowrap',
            }"
          >
            <span>{{ computedDataParams.status_str }}</span>
          </span>
        </p>
        <p
          style="
            padding: 0;
            margin: 0;
            color: #333333;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            padding: 0;
            margin: 0;
            font-family: 'Inter', sans-serif;
            margin-bottom: 8px;
            padding: 0;
            margin: 0;
          "
        >
          <span
            style="
              color: #333333;
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 1.5;
              letter-spacing: 0.0071428571em;
            "
          >{{ t('transactions.date_issued') }}:
          </span>
          <span
            style="
              color: #333333;
              font-size: 0.875rem;
              font-weight: 600;
              line-height: 1.5;
              letter-spacing: 0.0071428571em;
            "
          >{{ computedDataParams.transaction_date }}</span>
        </p>
        <p
          style="
            padding: 0;
            margin: 0;
            margin-bottom: 8px;
            color: #333333;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            padding: 0;
            margin: 0;
            font-family: 'Inter', sans-serif;
          "
        >
          <span
            style="
              color: #333333;
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 1.5;
              letter-spacing: 0.0071428571em;
            "
          >{{ t('transactions.due_date') }}:
          </span>
          <span
            style="
              color: #333333;
              font-size: 0.875rem;
              font-weight: 600;
              line-height: 1.5;
              letter-spacing: 0.0071428571em;
            "
          >{{ computedDataParams.due_date }}</span>
        </p>
      </div>

      <div></div>
    </div>

    <div
      style="padding: 28px 32px; flex-direction: row; display: flex; justify-content: space-between; flex-wrap: wrap"
    >
      <div>
        <p
          style="
            padding: 0;
            margin: 0;
            color: #333333;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            padding: 0;
            margin: 0;
            font-family: 'Inter', sans-serif;
            font-weight: 600;
            margin-bottom: 0.813rem;
          "
        >
          {{ t('transactions.invoice_to') }}:
        </p>
        <p
          style="
            padding: 0;
            margin: 0;
            color: #333333;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            padding: 0;
            margin: 0;
            font-family: 'Inter', sans-serif;
            margin-bottom: 4px;
          "
        >
          {{ computedDataParams.facility_name }}
        </p>
        <p
          style="
            padding: 0;
            margin: 0;
            color: #333333;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            padding: 0;
            margin: 0;
            font-family: 'Inter', sans-serif;
            margin-bottom: 4px;
          "
        >
          {{ computedDataParams.facility_email }}
        </p>
        <p
          style="
            padding: 0;
            margin: 0;
            color: #333333;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            padding: 0;
            margin: 0;
            font-family: 'Inter', sans-serif;
            margin-bottom: 4px;
          "
        >
          {{ computedDataParams.facility_phone }}
        </p>
      </div>
      <div v-if="computedDataParams.payment">
        <p
          style="
            padding: 0;
            margin: 0;
            color: #333333;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            padding: 0;
            margin: 0;
            font-family: 'Inter', sans-serif;
            font-weight: 600;
            margin-bottom: 0.813rem;
          "
        >
          {{ t('payment_methods.payment') }} #{{ computedDataParams.payment.id }}
          <span
            v-if="computedDataParams.status === 'C'"
            :style="{
              backgroundColor: `${resolveColor(computedDataParams.status)}30`,
              color: resolveColor(computedDataParams.status),
              caretColor: resolveColor(computedDataParams.status),
              fontSize: '12px',
              height: '20px',
              padding: '0 8px',
              borderRadius: '12px',
              textTransform: 'capitalize',
              fontWeight: '600',
              marginLeft: '8px',
              alignItems: 'center',
              cursor: 'default',
              lineHeight: '20px',
              maxWidth: '100%',
              outline: 'none',
              overflow: 'hidden',
              padding: '0 12px',
              position: 'relative',
              textDecoration: 'none',
              transitionDuration: '0.28s',
              transitionProperty: 'box-shadow, opacity',
              transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
              verticalAlign: 'middle',
              whiteSpace: 'nowrap',
            }"
          >
            <span>{{ computedDataParams.refunded }}</span>
          </span>
        </p>

        <table
          style="
            display: table;
            border-collapse: separate;
            text-indent: initial;
            border-spacing: 2px;
            border-color: grey;
            margin-bottom: 15px;
          "
        >
          <tr style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit">
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ t('payment_methods.brand') }}:
            </td>
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ computedDataParams.payment.payment_method.brand }}
            </td>
          </tr>
          <tr style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit">
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ t('payment_methods.card_number') }}:
            </td>
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ computedDataParams.payment.payment_method.number }}
            </td>
          </tr>
        </table>

        <div v-if="computedDataParams.payment">
          <p
            style="
              padding: 0;
              margin: 0;
              color: #333333;
              font-weight: 400;
              font-size: 0.875rem;
              line-height: 1.5;
              letter-spacing: 0.0071428571em;
              padding: 0;
              margin: 0;
              font-family: 'Inter', sans-serif;
              margin-bottom: 4px;
            "
          >
            {{ computedDataParams.payment.payment_method.owner_name }}
          </p>
          <p
            style="
              padding: 0;
              margin: 0;
              color: #333333;
              font-weight: 400;
              font-size: 0.875rem;
              line-height: 1.5;
              letter-spacing: 0.0071428571em;
              padding: 0;
              margin: 0;
              font-family: 'Inter', sans-serif;
              margin-bottom: 4px;
            "
          >
            {{ computedDataParams.payment.payment_method.billing_address }}
          </p>
          <p
            style="
              padding: 0;
              margin: 0;
              color: #333333;
              font-weight: 400;
              font-size: 0.875rem;
              line-height: 1.5;
              letter-spacing: 0.0071428571em;
              padding: 0;
              margin: 0;
              font-family: 'Inter', sans-serif;
              margin-bottom: 4px;
            "
          >
            {{ computedDataParams.payment.payment_method.billing_phone }}
          </p>
          <p
            style="
              padding: 0;
              margin: 0;
              color: #333333;
              font-weight: 400;
              font-size: 0.875rem;
              line-height: 1.5;
              letter-spacing: 0.0071428571em;
              padding: 0;
              margin: 0;
              font-family: 'Inter', sans-serif;
              margin-bottom: 4px;
            "
          >
            {{ computedDataParams.payment.payment_method.billing_email }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="paymentParams"
      style="
        background-color: #ffffff;
        color: #333333;
        line-height: 1.5;
        max-width: 100%;
        border-radius: 5px;
        padding: 0px 32px;
      "
    >
      <div style="overflow-x: auto; overflow-y: hidden">
        <table style="width: 100%; border-spacing: 0; font-family: 'Inter', sans-serif">
          <thead style="display: table-header-group; vertical-align: middle; border-color: inherit">
            <tr style="display: table-row; vertical-align: inherit; border-color: inherit">
              <th
                style="
                  border-bottom: thin solid #e7e3fc24;
                  text-align: left;
                  background-color: #333333 !important;
                  user-select: none;
                  font-size: 0.75rem;
                  height: 3.375rem;
                  padding: 0 16px;
                  vertical-align: inherit;
                  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                  color: #ffffff !important;
                  display: table-cell;
                "
              >
                ID
              </th>
              <th
                style="
                  border-bottom: thin solid #e7e3fc24;
                  text-align: left;
                  background-color: #333333 !important;
                  user-select: none;
                  font-size: 0.75rem;
                  height: 3.375rem;
                  padding: 0 16px;
                  vertical-align: inherit;
                  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                  color: #ffffff !important;
                  display: table-cell;
                "
              >
                {{ t('videos.video').toUpperCase() }}
              </th>
              <th
                style="
                  border-bottom: thin solid #e7e3fc24;
                  text-align: left;
                  background-color: #333333 !important;
                  user-select: none;
                  font-size: 0.75rem;
                  height: 3.375rem;
                  padding: 0 16px;
                  vertical-align: inherit;
                  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                  color: #ffffff !important;
                  display: table-cell;
                "
              >
                {{ t('sports.sport').toUpperCase() }}
              </th>
              <th
                style="
                  border-bottom: thin solid #e7e3fc24;
                  text-align: left;
                  background-color: #333333 !important;
                  user-select: none;
                  font-size: 0.75rem;
                  height: 3.375rem;
                  padding: 0 16px;
                  vertical-align: inherit;
                  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                  color: #ffffff !important;
                  display: table-cell;
                "
              >
                {{ t('transactions.start_time').toUpperCase() }}
              </th>
              <th
                style="
                  border-bottom: thin solid #e7e3fc24;
                  text-align: left;
                  background-color: #333333 !important;
                  user-select: none;
                  font-size: 0.75rem;
                  height: 3.375rem;
                  padding: 0 16px;
                  vertical-align: inherit;
                  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                  color: #ffffff !important;
                  display: table-cell;
                "
              >
                {{ t('transactions.end_time').toUpperCase() }}
              </th>
              <th
                style="
                  border-bottom: thin solid #e7e3fc24;
                  text-align: left;
                  background-color: #333333 !important;
                  user-select: none;
                  font-size: 0.75rem;
                  height: 3.375rem;
                  padding: 0 16px;
                  vertical-align: inherit;
                  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                  color: #ffffff !important;
                  display: table-cell;
                "
              >
                {{ t('transactions.price').toUpperCase() }}
              </th>
            </tr>
          </thead>

          <tbody
            style="
              display: table-row-group;
              vertical-align: middle;
              border-color: inherit;
              border-bottom: thin solid #a199cc !important;
            "
          >
            <tr
              v-for="item in paymentParams"
              :key="item.id"
              style="display: table-row; vertical-align: inherit; border-color: inherit"
            >
              <td
                style="
                  border-bottom: thin solid #a199cc !important;
                  font-size: 0.875rem;
                  height: 3.125rem;
                  padding: 0 16px;
                  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                  color: #333333;
                  white-space: nowrap;
                  display: table-cell;
                  vertical-align: inherit;
                "
              >
                {{ item.id }}
              </td>
              <td
                style="
                  border-bottom: thin solid #a199cc !important;
                  font-size: 0.875rem;
                  height: 3.125rem;
                  padding: 0 16px;
                  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                  color: #333333;
                  white-space: nowrap;
                  display: table-cell;
                  vertical-align: inherit;
                "
              >
                {{ item.field_name }} {{ item.video_date }}
              </td>
              <td
                style="
                  border-bottom: thin solid #a199cc !important;
                  font-size: 0.875rem;
                  height: 3.125rem;
                  padding: 0 16px;
                  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                  color: #333333;
                  white-space: nowrap;
                  display: table-cell;
                  vertical-align: inherit;
                "
              >
                {{ item.sport_name }}
              </td>
              <td
                style="
                  border-bottom: thin solid #a199cc !important;
                  font-size: 0.875rem;
                  height: 3.125rem;
                  letter-spacing: 0.7px;
                  padding: 0 16px;
                  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                  color: #333333;
                  white-space: nowrap;
                  display: table-cell;
                  vertical-align: inherit;
                "
              >
                {{ item.start_time }}
              </td>
              <td
                style="
                  border-bottom: thin solid #a199cc !important;
                  font-size: 0.875rem;
                  height: 3.125rem;
                  letter-spacing: 0.7px;
                  padding: 0 16px;
                  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                  color: #333333;
                  white-space: nowrap;
                  display: table-cell;
                  vertical-align: inherit;
                "
              >
                {{ item.end_time }}
              </td>
              <td
                style="
                  border-bottom: thin solid #a199cc !important;
                  font-size: 0.875rem;
                  height: 3.125rem;
                  letter-spacing: 0.7px;
                  padding: 0 16px;
                  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                  color: #333333;
                  white-space: nowrap;
                  display: table-cell;
                  vertical-align: inherit;
                "
              >
                {{ computedDataParams && computedDataParams.subtotal ? formatCurrency(computedDataParams.subtotal) : '$0.00' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      style="
        padding: 70px 32px 28px 32px;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-family: 'Inter', sans-serif;
      "
    >
      <div>
        <p
          style="
            padding: 0;
            margin: 0;
            margin-bottom: 4px;
            display: block;
            color: #333333;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
          "
        >
          {{ t('transactions.thanks_buy') }}
        </p>
      </div>

      <div>
        <table
          style="
            display: table;
            border-collapse: separate;
            box-sizing: border-box;
            text-indent: initial;
            border-spacing: 2px;
            border-color: grey;
            margin: 0;
            padding: 0;
            width: 100%;
          "
        >
          <tr style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit">
            <td
              style="
                padding-right: 64px;
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
              "
            >
              Subtotal:
            </td>
            <th
              style="
                text-align: right;
                display: table-cell;
                vertical-align: inherit;
                font-weight: bold;
                color: #333333;
                font-size: 0.875rem;
                line-height: 1.5;
                letter-spacing: 0.7px;
              "
            >
              {{ computedDataParams.subtotal ? formatCurrency(computedDataParams.subtotal) : '$0.00' }}
            </th>
          </tr>
          <tr
            v-if="computedDataParams.discount && computedDataParams.discount !== 0"
            style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit"
          >
            <td
              style="
                padding-right: 64px;
                display: table-cell;
                vertical-align: inherit;
                color: #56ca00;
                caret-color: #57c900;
                font-weight: 700;
                font-size: 0.875rem;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
              "
            >
              {{ t('video_details.discount') }}:
            </td>
            <th
              style="
                text-align: right;
                display: table-cell;
                vertical-align: inherit;
                font-weight: bold;
                color: #56ca00;
                caret-color: #57c900;
                color: #333333;
                font-size: 0.875rem;
                line-height: 1.5;
                letter-spacing: 0.7px;
              "
            >
              {{ computedDataParams.discount ? `(${formatCurrency(computedDataParams.discount)})` : '($0.00)' }}
            </th>
          </tr>

          <tr style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit">
            <td
              style="
                padding-right: 64px;
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
              "
            >
              {{ t('video_details.tax') }}:
            </td>
            <th
              style="
                text-align: right;
                display: table-cell;
                vertical-align: inherit;
                font-weight: bold;
                color: #333333;
                font-size: 0.875rem;
                line-height: 1.5;
                letter-spacing: 0.7px;
              "
            >
              {{ computedDataParams.tax ? formatCurrency(computedDataParams.tax) : '$0.00' }}
            </th>
          </tr>
          <tr style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit">
            <td
              style="
                padding-right: 64px;
                display: table-cell;
                vertical-align: inherit;
                color: #56ca00;
                caret-color: #57c900;
                font-weight: 700;
                font-size: 0.875rem;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
              "
            >
              {{ t('video_details.comission') }}:
            </td>
            <th
              style="
                text-align: right;
                display: table-cell;
                vertical-align: inherit;
                font-weight: bold;
                color: #56ca00;
                caret-color: #57c900;
                font-size: 0.875rem;
                line-height: 1.5;
                letter-spacing: 0.7px;
              "
            >
              {{ computedDataParams.comission ? `(${formatCurrency(computedDataParams.comission)})` : '($0.00)' }}
            </th>
          </tr>
        </table>
        <div style="border-bottom: #333333 solid 1px"></div>
        <table
          style="
            display: table;
            border-collapse: separate;
            box-sizing: border-box;
            text-indent: initial;
            border-spacing: 2px;
            border-color: grey;
            margin: 0;
            padding: 0;
            width: 100%;
          "
        >
          <tr style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit">
            <td
              style="
                padding-right: 64px;
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
              "
            >
              Total:
            </td>
            <th
              style="
                text-align: right;
                display: table-cell;
                vertical-align: inherit;
                font-weight: bold;
                color: #333333;
                font-size: 0.875rem;
                line-height: 1.5;
                letter-spacing: 0.7px;
              "
            >
              {{ computedDataParams && computedDataParams.amount ? formatCurrency(computedDataParams.amount) : '$0.00' }}
            </th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { formatCurrency } from '@core/utils'
import themeConfig from '@themeConfig'

export default {
  props: {
    dataParams: {
      type: Object,
      required: true,
    },
    paymentParams: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useUtils()

    const dateNow = new Date().toISOString().substring(0, 10)
    const computedDataParams = computed(() => props.dataParams)

    const resolveColor = status => {
      if (status === 'N') return '#FF4C51'
      if (status === 'C') return '#FFB400'
      if (status === 'F') return '#16B1FF'
      if (status === 'P') return '#56CA00'

      return '#333333'
    }

    return {
      dateNow,

      // computed
      computedDataParams,

      // methods
      resolveColor,
      formatCurrency,

      // i18n
      t,

      appLogo: themeConfig.app.logo,
    }
  },
}
</script>
